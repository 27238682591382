<template>
  <div class="inner job-posting">
    <sub-title
      title="근무요청"
      description="회원님에게 근무를 요청받은 일자리 정보를 확인할 수 있습니다."
    >
    </sub-title>
    <router-view></router-view>
  </div>
</template>

<script>
import SubTitle from 'Common/SubTitle'

export default {
  components: { SubTitle },
}
</script>

<style></style>
